// initial state
import getEnv from '@/utils/env'

const REQUEST_URL = getEnv('REQUEST_URL');

import axios from 'axios';

const state = () => ({
  profileData: [],
  profileIndex: 0,
  profilePageData: [],
  profilePageStats: null,
  friendSearchData: [],
  followingData: [],
  followmeData: [],
  notificationData: null,
  requestData: null,
  isBlocked: false,
  pic: localStorage.getItem('profile_pic') || '',
  id: localStorage.getItem('profile_id') || '',
  locale: localStorage.getItem('profile_local') || 'en',
  fullname: localStorage.getItem('profile_name') || '',
  username: localStorage.getItem('profile_username') || '',
  accountType: localStorage.getItem('profile_account_type') || '',
  reporter: localStorage.getItem('profile_reporter') || null,
  certified: localStorage.getItem('profile_certified') || null,
  usState: localStorage.getItem('profile_us_state') || '',
  premiumContent: localStorage.getItem('premium_content') || '',
  private: localStorage.getItem('profile_private') || '',
  shownew: localStorage.getItem('shownew') || '',
});

// getters
const getters = {}

// actions
const actions = {
  async updateMe(context, params) {
    if (params.file != null) {
      const formData = new FormData();
      const photo = params.file;
      formData.append('thumb_pik', photo);
      formData.append('first_name', params.fullname);
      return axios.post(REQUEST_URL + "user/updateme", formData,
        {
          headers: {
            'Authorization': context.rootState.auth.token,
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(res => {
          //console.log(res);
          context.commit('setRequest', res.data);
          return res;
        })
        .catch(error => {
          /*if (error.response) {
            // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            } */
          //console.log(error.config);
          console.log(error);
          return ({ 'error': error.message });
        });
    } else {
      return axios.post(REQUEST_URL + "user/updateme",  { 
        first_name: params.fullname ,
        state: params.usState
      },
        {
          headers: {
            'Authorization': context.rootState.auth.token,
          }
        }
      )
        .then(res => {
          // console.log(res.data, 'return_val');
          context.commit('setRequest', res.data);
          return res;
        })
        .catch(error => {
          return ({ 'error': error.message });
        });
    }
  },
  async updatePrivacy(context, params) {
    return axios.post(REQUEST_URL + "user/updateme", {
      private: params.privacy
    },
      {
        headers: {
          'Authorization': context.rootState.auth.token,
        }
      })
      .then(res => {
        context.commit('setRequest', res.data);
        return res;
      })
      .catch(error => {
        return ({ 'error': error.message });
      });
  },
  async updateProfile(context, params) {
    var values = {
      first_name: params.first_name,
      user_name: params.user_name,
      bio: params.bio,
      email: params.email,
      phone: params.phone,
      zipcode: params.zipcode,
      dob: params.dob
    }

    if (params.locale != undefined) {
      values = {
        locale: params.locale
      }
    } else if (params.password != '') {
      values = {
        first_name: params.first_name,
        user_name: params.user_name,
        access: params.password,
        bio: params.bio,
        email: params.email,
        phone: params.phone,
        zipcode: params.zipcode,
        dob: params.dob
      }
    }
    return axios.post(REQUEST_URL + "user/updateme", values,
      {
        headers: {
          'Authorization': context.rootState.auth.token,
        }
      })
      .then(res => {
        if (res.data['message'] == null && res.data['error'] == null) {
          context.commit('setRequest', res.data);
        }
        //console.log(res.data, 'test data');
        return res.data;

      })
      .catch(error => {
        return ({ 'error': error.message });
      });
  },
  async deleteAccount(context, params) {
    let values = {
      password_verify: params.password_verify,
    }

    return axios.post(REQUEST_URL + "user/remove-my-account", values,
      {
        headers: {
          'Authorization': context.rootState.auth.token,
        }
      })
      .then(res => {
        return res.data;
      })
      .catch(error => {
        return ({ 'error': error.message });
      });
  },
  async updatePhoto(context, params) {
    if (params.file != null) {
      // const blob = await fetch(base64).then(res => res.blob());
      const formData = new FormData();
      const photo = params.file;

      if (params.type == "profile") {
        formData.append('profile_pic', photo);
      }
      if (params.type == "banner") {
        formData.append('banner_pic', photo);
      }
      return axios.post(REQUEST_URL + "user/updatephotos",
        formData,
        {
          headers: {
            'Authorization': context.rootState.auth.token,
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(res => {
          context.commit('setRequest', res.data);
          // return res;
        })
        .catch(error => {
          return ({ 'error': error.message });
        });

    }
  },
  async info(context) {
    return axios.get(REQUEST_URL + 'user/myprofile',
      {
        headers: {
          'Authorization': context.rootState.auth.token
        }
      })
      .then(res => {
        context.commit('setRequest', res.data);
        return true;
      })
      .catch(error => {
        return ({ 'error': error.message + ' ' + REQUEST_URL });
      });
  },
  async premiumInfo(context) {
    var endpoint = 'user/my-premium-profile';
    // if(profileId != "") endpoint = "/user/profile/" + profileId;
    axios.get(REQUEST_URL + endpoint,
      {
        headers: {
          'Authorization': context.rootState.auth.token
        }
      })
      .then(res => {
        // console.log(res.data);
        context.commit('setRequest', res.data);
        //this.dispatch("helpers/getNotifyBadges");
      })
      .catch(error => {
        return ({ 'error': error.message + ' ' + REQUEST_URL });
      }).then(function () {
        // always executed
        return ({ 'error': REQUEST_URL + endpoint });
      });
  },
  async otherUserInfo(context, params) {
    var endpoint = '';
    var token = context.rootState.auth.anonToken;
    if (context.rootState.auth.token != '') {
      token = context.rootState.auth.token;
    }

    if (params.userId != null) {
      endpoint = 'users/' + params.userId;
    } else if (params.username != null) {
      endpoint = 'user/viewbyname/?user_name=' + params.username;
    }
    return axios.get(REQUEST_URL + endpoint,
      {
        headers: {
          'Authorization': token
        }
      })
      .then(res => {
        context.commit('setPageRequest', res.data);
        if (context.rootState.auth.token != '') {
          context.dispatch("friendStatus", {
            id: res.data.id
          });
        }
        return res.data;
      })
      .catch(error => {
        return ({ 'error': error.message + ' ' + REQUEST_URL });
      });
  },
  async viewUserInfo(context, params) {
    var endpoint = '';
    var token = context.rootState.auth.anonToken;
    if (context.rootState.auth.token != '') {
      token = context.rootState.auth.token;
    }

    if (params.userId != null) {
      endpoint = 'users/' + params.userId;
    } else if (params.username != null) {
      endpoint = 'user/viewbyname/?user_name=' + params.username;
    }
    return axios.get(REQUEST_URL + endpoint,
      {
        headers: {
          'Authorization': token
        }
      })
      .then(res => {
        return res.data;
      })
      .catch(error => {
        return ({ 'error': error.message + ' ' + REQUEST_URL });
      });
  },
  async stats(context, params) {
    var token = context.rootState.auth.anonToken;
    if (context.rootState.auth.token != '') {
      token = context.rootState.auth.token;
    }

    var endpoint = 'user/stats';
    if (params.userId != null) {
      endpoint = 'user/profilestats?userid=' + params.userId;
    }
    // if(profileId != "") endpoint = "/user/profile/" + profileId;
    axios.get(REQUEST_URL + endpoint,
      {
        headers: {
          'Authorization': token
        }
      })
      .then(res => {
        // console.log(res.data);
        var results = res.data[0];
        results['user_id'] = params.userId;
        //console.log(res.data);
        context.commit('setStatsRequest', results);
      })
      .catch(error => {
        return ({ 'error': error.message + ' ' + REQUEST_URL });
      }).then(function () {
        // always executed
        return ({ 'error': REQUEST_URL + endpoint });
      });
  },
  async friendSearch(context, params) {
    // user_id optional
    var url = 'friend/search?search=' + params.search;

    // NOTE: functions were name  wrong in the API
    if (params.type == 'myfollows') {
      url = url.concat('&mutualtype=following');
    } else if (params.type == "followme") {
      url = url.concat('&mutualtype=followme');
    }
    if (params.userId != null && params.userId != 0) {
      url = url.concat('&user_id=' + params.userId);
    }
    return axios.get(REQUEST_URL + url,
      {
        headers: {
          'Authorization': context.rootState.auth.token
        }
      })
      .then(res => {
        if (params.type == 'followme') {
          context.commit('setFollowme', [res.data, params.userId]);
        } else if (params.type == "myfollows") {
          context.commit('setFollowing', [res.data, params.userId]);
        }
        return true;
      })
      .catch(error => {
        return ({ 'error': error.message + ' ' + REQUEST_URL });
      });
  },
  async browseFriends(context, params) {
    var url = '';
    // NOTE: functions were name  wrong in the API
    if (params.type == 'followme') {
      url = 'friend/followme';
    } else if (params.type == "myfollows") {
      url = 'friend/myfollows';
    }
    url = url.concat('?currentPage=' + params.currentPage + '&totalList=' + params.totalList);
    if (params.userId != null && params.userId != 0) {
      url = url.concat('&id=' + params.userId);
    }
    return axios.get(REQUEST_URL + url,
      {
        headers: {
          'Authorization': context.rootState.auth.token
        }
      })
      .then(res => {
        if (params.currentPage>0) {
          if (params.type == 'followme') {
            context.commit('setAddFollowme', [res.data, params.userId]);
          } else if (params.type == "myfollows") {
            context.commit('setAddFollowing', [res.data, params.userId]);
          }
        } else {
          if (params.type == 'followme') {
            context.commit('setFollowme', [res.data, params.userId]);
          } else if (params.type == "myfollows") {
            context.commit('setFollowing', [res.data, params.userId]);
          }
        }
        return true;
      })
      .catch(error => {
        return ({ 'error': error.message + ' ' + REQUEST_URL });
      });
  },
  async updateWebsiteLink(context, params) {
    return axios.post(REQUEST_URL + "user/editwebsite", {
      id: params.id,
      url: params.url,
      name: params.name,
    },
      {
        headers: {
          'Authorization': context.rootState.auth.token,
        }
      })
      .then(res => {
        //context.commit('setRequest', res.data);
        return res;
      })
      .catch(error => {
        return ({ 'error': error.message });
      });
  },
  async addWebsiteLink(context, params) {
    return axios.post(REQUEST_URL + "user/addwebsite", {
      url: params.url,
      name: params.name,
    },
      {
        headers: {
          'Authorization': context.rootState.auth.token,
        }
      })
      .then(res => {
        // context.commit('setRequest', res.data);
        return res;
      })
      .catch(error => {
        return ({ 'error': error.message });
      });
  },
  async deleteWebsiteLink(context, params) {
    return axios.delete(REQUEST_URL + "user/removewebsite/?id=" + params.id,
      {
        headers: {
          'Authorization': context.rootState.auth.token,
        }
      })
      .then(res => {
        // context.commit('setRequest', res.data);
        return res;
      })
      .catch(error => {
        return ({ 'error': error.message });
      });
  },
  async notifications(context, params) {
    var url = 'pushes';
    var showSales = '';
    // NOTE: functions were name  wrong in the API
    if (params.type == "subscriptions") {
      showSales = '&showsales=1';
    }
    url = url.concat('?currentPage=' + params.currentPage + '&totalList=' + params.totalList + showSales);

    return await axios.get(REQUEST_URL + url,
      {
        headers: {
          'Authorization': context.rootState.auth.token
        }
      })
      .then(res => {
        if (params.update) {
          if(res.data.length>0){
            context.commit('setUpdateNotifications', res.data);
          }
        } else {
          context.commit('setNotifications', res.data);
        }
        return true;
      })
      .catch(error => {
        return ({ 'error': error.message + ' ' + REQUEST_URL });
      });
  },
  requests(context) {
    var url = 'requests';
    axios.get(REQUEST_URL + url,
      {
        headers: {
          'Authorization': context.rootState.auth.token
        }
      })
      .then(res => {
        context.commit('setRequests', res.data);
      })
      .catch(error => {
        return ({ 'error': error.message + ' ' + REQUEST_URL });
      });
  },
  async confirmRequest(context, params) {
    var url = 'request/confirm';
    return axios.post(REQUEST_URL + url, {
      piklist_id: params.storyid,
      type: params.type,
      friend_id: params.friend_id,
      request_id: params.request_id
    },
      {
        headers: {
          'Authorization': context.rootState.auth.token
        }
      })
      .then(() => {
        return true;
      })
      .catch(error => {
        return ({ 'error': error.message + ' ' + REQUEST_URL });
      });
  },
  async ignoreRequest(context, params) {
    return axios.delete(REQUEST_URL + 'requests/' + params.request_id,
      {
        headers: {
          'Authorization': context.rootState.auth.token
        }
      })
      .then(() => {
        return true;
      })
      .catch(error => {
        return ({ 'error': error.message + ' ' + REQUEST_URL });
      });
  },
  async unfriend(context, params) {
    return axios.delete(REQUEST_URL + 'friends/' + params.id,
      {
        headers: {
          'Authorization': context.rootState.auth.token
        }
      })
      .then(res => {
        return res;
      })
      .catch(error => {
        return ({ 'error': error.message + ' ' + REQUEST_URL });
      });
  },
  async friend(context, params) {
    return axios.post(REQUEST_URL + 'friends', {
      friend_id: params.id
    },
      {
        headers: {
          'Authorization': context.rootState.auth.token
        }
      })
      .then(res => {
        return res;
      })
      .catch(error => {
        return ({ 'error': error.message + ' ' + REQUEST_URL });
      });
  },
  async friendRequest(context, params) {
    return axios.post(REQUEST_URL + "requests", {
      to_id: params.id,
      type: '0',
    },
      {
        headers: {
          'Authorization': context.rootState.auth.token,
        }
      })
      .then(res => {
        return res;
      })
      .catch(error => {
        return ({ 'error': error.message });
      });
  },
  async friendStatus(context, params) {
    return axios.get(REQUEST_URL + "friend/status?friendid=" + params.id,
      {
        headers: {
          'Authorization': context.rootState.auth.token,
        }
      })
      .then(res => {
        context.commit('addFriendStatus', res.data);
        //return res;
      })
      .catch(error => {
        return ({ 'error': error.message });
      });
  },
}

// mutations
const mutations = {
  setRequest(state, response) {
    state.uploadComplete = true;
    localStorage.setItem('profile_pic', response.profile_pic);
    state.pic = response.profile_pic;
    localStorage.setItem('profile_id', response.id);
    state.id = response.id;
    localStorage.setItem('profile_local', response.locale);
    state.locale = response.locale;
    localStorage.setItem('profile_name', response.first_name);
    state.fullname = response.first_name;
    localStorage.setItem('profile_username', response.user_name);
    state.username = response.user_name;
    localStorage.setItem('profile_account_type', response.account_type);
    state.accountType = response.account_type;
    localStorage.setItem('profile_reporter', response.ecb_reporter);
    state.reporter = response.ecb_reporter;
    localStorage.setItem('profile_certified', response.certified);
    state.certified = response.certified;
    localStorage.setItem('profile_us_state', response.state);
    state.usState = response.state;
    localStorage.setItem('premium_content', response.premium_content);
    state.premiumContent = response.premium_content;
    localStorage.setItem('profile_private', response.private);
    state.private = response.private;
    if (response.phone != undefined && response.phone != '') {
      let text = response.phone;
      const myArray = text.split(" ");
      if (myArray.length == 1) {
        response.countryCode = '+1';
        response.phone = text;
      } else {
        let countryCode = '';
        if (myArray[0].substring(0, 1) == "+") {
          countryCode = myArray[0];
        }
        response.countryCode = countryCode;
        response.phone = myArray[1];
      }
    }
    state.profileData = response;
    state.profilePageData[response.id] = response;
  },
  resetProfile(state) {
    localStorage.removeItem('profile_pic');
    state.pic = '';
    localStorage.removeItem('profile_id');
    state.id = '';
    localStorage.removeItem('profile_local');
    state.locale = 'en';
    localStorage.removeItem('profile_name');
    state.fullname = '';
    localStorage.removeItem('profile_username');
    state.username = '';
    localStorage.removeItem('profile_account_type');
    state.accountType = '';
    localStorage.removeItem('profile_reporter');
    state.reporter = '';
    localStorage.removeItem('profile_certified');
    state.certified = '';
    localStorage.removeItem('profile_us_state');
    state.usState = '';
    localStorage.removeItem('premium_content');
    state.premiumContent = '';
    localStorage.removeItem('profile_private');
    state.private = '';
  },
  setPageRequest(state, response) {
    if (response.id != null) {
      state.isBlocked = false;
      state.profileIndex = response.id;
      state.profilePageData[response.id] = response;
    }else if(response.message=='user blocked'){
      state.isBlocked = true;
    }
  },
  addFriendStatus(state, response) {
    var existing = state.profilePageData[response.user_id];
    existing.status = response;
    state.profilePageData[response.user_id] = existing;
  },
  setStatsRequest(state, response) {
    state.profilePageStats = response;
  },
  setFollowme(state, response) {
    let responseData = response[0];
    const userId = response[1];
    state.followmeData[userId] = responseData;
  },
  setAddFollowme(state, response) {
    let responseData = response[0];
    const userId = response[1];
    if(response!=null){
      let existingFriends = state.followmeData[userId];
      let updatedArray = existingFriends.concat(responseData);
      state.followmeData[userId] = updatedArray;
    }else{
      state.followmeData[userId] = null;
    }
  },
  setFollowing(state, response) {
    let responseData = response[0];
    const userId = response[1];
    state.followingData[userId] = responseData;
  },
  setAddFollowing(state, response) {
    let responseData = response[0];
    const userId = response[1];
    if(response!=null){
      let existingFriends = state.followingData[userId];
      let updatedArray = existingFriends.concat(responseData);
      state.followingData[userId] = updatedArray;
    }else{
      state.followingData[userId] = null;
    }
  },
  /*setFriends(state, response) {
    let responseData = response[0];
    const userId = response[1];
    state.friendSearchData[userId] = responseData;
  },
  setAddFriends(state, response) {
    let responseData = response[0];
    const userId = response[1];
    if(response!=null){
      let existingFriends = state.friendSearchData[userId];
      let updatedArray = existingFriends.concat(responseData);
      state.friendSearchData[userId] = updatedArray;
    }else{
      state.friendSearchData[userId] = null;
    }
  }, */
  setUpdateNotifications(state, response) {
    let existingNotify = state.notificationData;
    let updatedArray = existingNotify.concat(response);
    state.notificationData = updatedArray;
  },
  setNotifications(state, response) {
    state.notificationData = response;
  },
  setRequests(state, response) {
    state.requestData = response;
  },
  setShowWhatsNew(state, response) {
    console.log('setting', response);
    state.shownew = response;
    localStorage.setItem('shownew', response);
  },
  logout(state){
    state.profileData = []
    state.profileIndex =  0;
    state.profilePageData =  [];
    state.profilePageStats =  null;
    state.friendSearchData =  [];
    state.followingData =  [];
    state.followmeData =  [];
    state.notificationData =  null;
    state.requestData =  null;
    state.isBlocked =  false;
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
