import { createApp } from 'vue'
import { createHead, VueHeadMixin } from '@vueuse/head'
import { createI18n } from 'vue-i18n'
import App from './App.vue'
import router from './router'
import store from './store'
import { register } from 'swiper/element/bundle';

import { IonicVue } from '@ionic/vue';
import * as Sentry from "@sentry/vue";
import { createGmapVuePlugin } from '@gmap-vue/v3';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';
import 'intl-tel-input/build/css/intlTelInput.css';

/* Theme variables */
import './theme/variables.css';
import './theme/dash.css';
import '@gmap-vue/v3/dist/style.css';

// import '../node_modules/video.js/dist/video-js.min.css';
import './registerServiceWorker'

//import messages from '@intlify/unplugin-vue-i18n/messages'
import en from './locales/en'
import es from './locales/es'
import pt from './locales/pt'

let setLocale = 'en';
let profileLocale = store.state.profile.locale;
if(profileLocale=='en' || profileLocale=='es' || profileLocale=='pt'){
  setLocale = profileLocale;
}

const i18n = createI18n({
  locale: setLocale,
  legacy: false,
  fallbackLocale: 'en', // set fallback locale
  messages: {
    en,
    pt,
    es
  }
})

// Call this function when your app start
async function OneSignalInit() {
  // Uncomment to set OneSignal device logging to VERBOSE  
  //window.plugins.OneSignal.Debug.setLogLevel(6);

  // NOTE: Update the setAppId value below with your OneSignal AppId.
  window.plugins.OneSignal.initialize("4bc05e94-ed8e-4578-88df-43367e34d0c6");
  /* window.plugins.OneSignal.Notifications.setNotificationOpenedHandler(function(opened) {
    let notificationData = JSON.stringify(opened);
    console.log('notificationOpenedCallback: ' + notificationData);
  }); 
  const listener = (PushSubscriptionChangedState) => {
    console.log("Push subscription changed: " + (PushSubscriptionChangedState));
  };

  // Add the listener
  window.plugins.OneSignal.User.pushSubscription.addEventListener("change", listener); */

  window.plugins.OneSignal.Notifications.addEventListener("click", (jsonData) => {
    let notificationData = JSON.stringify(jsonData)
    console.log('notificationOpenedCallback: ' + notificationData);
  }); 

  // iOS - Prompts the user for notification permissions.
  //    * Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 6) to better communicate to your users what notifications they will get.
  /*   window["plugins"].OneSignal.Notifications.requestPermission(function(accepted) {
      console.log("User accepted notifications: " + accepted);
    }); */
}

register(); 

let traceTargets = ["localhost"];
if(import.meta.env['VITE_REQUEST_URL'=='dev.pikmobile.com']){
  traceTargets = ["localhost", /^https:\/\/dev\.pikmobile\.com/];
}else if(import.meta.env['VITE_REQUEST_URL'=='api.choicesocial.us']){
  traceTargets = ["localhost", /^https:\/\/api\.choicesocial\.us/];
}else if(import.meta.env['VITE_REQUEST_URL'=='dev-api.franksocial.com']){
  traceTargets = ["localhost", /^https:\/\/dev-api\.franksocial\.com/];
}else if(import.meta.env['VITE_REQUEST_URL'=='api.franksocial.com']){
  traceTargets = ["localhost", /^https:\/\/api\.franksocial\.com/];
}

if(import.meta.env['VITE_APP_NAME']=='FrankSocial'){
  document.addEventListener("deviceready", OneSignalInit, false);
}

const head = createHead()
const app = createApp(App)
  .use(IonicVue)
  .use(store)
  .use(router)
  .use(i18n)
  .use(createGmapVuePlugin({
    load: { key: 'AIzaSyBKvamvBCjTyBhfkhbthecKX7fP5ROhwOQ' },
  }))
  .mixin(VueHeadMixin)
  .use(head);

  Sentry.init({
    app,
    dsn: import.meta.env['VITE_SENTRY_DSN'],
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: traceTargets,
    // Session Replay
    replaysSessionSampleRate: 0.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    beforeSend(event) {
      // Modify or drop the event here
      //console.log(event.request.headers['User-Agent'], 'request');
      let agent = event.request.headers['User-Agent'];
      if (agent!=null && agent.includes('Prerender (+https://github.com/prerender/prerender)')) {
        // don't send any prerender data
        console.log('prerender browser');
        return null;
      }
      return event;
    },
  });
  
router.isReady().then(() => {
  app.mount('#app');
});
